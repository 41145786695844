<template>
  <v-expansion-panels accordion="" multiple v-model="panels" focusable :readonly="$vuetify.breakpoint.mdAndUp" class="panels">
    <v-expansion-panel class="mb-3" v-for="(item,index) in items" :key="index" @change="posicion(item.ref)" :id="item.ref" >
      <v-expansion-panel-header>
        <div class="subtitle-1 font-weight-medium primary--text">{{index+1}}{{item.titulo}} <v-chip small v-if="item.chip" class="ml-3" dark color="secondary">{{item.chip}}</v-chip></div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="mt-5">
        <component  :is="item.componente" :propiedades="item.props"></component>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
export default {
  components: {
    'InfoRegistro':()=>import("@/components/Registro"),
    'InfoEstado':()=>import("@/components/EstadoRegistro"),
    'InfoProfesion':()=>import("@/components/Profesion"),
    //'CodigoPagoTramiteRegistro':()=>import("@/components/CodigoPagoTramiteRegistro"),
    'MetodoPago':()=>import("@/components/MetodoPago"),
    'CertificacionTitulo':()=>import("@/components/CertificacionTitulo"),
    'Juramento':()=>import("@/components/Juramento")
  },
  data() {
    return {
      panels: [0, 1, 2, 3],
      cantidad: 6,
      maxItems:this.$vuetify.breakpoint.mdAndUp?6:2,
      items:[
        {
          titulo:'.- Estado del Registro',
          componente:'InfoEstado',
        },
        {
           titulo:'.- Información del Registro',
          componente:'InfoRegistro',
        },
        {
          titulo:'.- Información del Título Profesional Registrado',
          componente:'InfoProfesion',
          ref:"panelDirecciones"
        },
        {
          titulo:'.- Información de Pago',
          componente:'MetodoPago',
          ref:"panelCodigoPagoTramite",
          chip:this.$store.getters['abogado/getMetodoPago'],
          props:{
            url:`${this.$apiUrl}abogado-ciudadano/metodo-pago-registro`,
            tipo:'REGISTRO',
            metodoPago:this.$store.getters['abogado/getMetodoPago']
          }
        },
        {
           titulo:'.- Información de la Certificación del Título',
          componente:'CertificacionTitulo',
          ref:"panelCertificacion"
        },
        {
           titulo:'.- Información del Juramento',
          componente:'Juramento',
          ref:"panelJuramento"
        }
      ]
    };
  },
  mounted(){
    this.iniciarPanel()
  },
  methods:{
    iniciarPanel(){
       var i=0;
       var array=[];
       for (let index = 0; index < this.maxItems; index++) {
         array.push(i);
         i++;
       } 
       this.panels=array;
      // const metodoPago = this.$store.getters.getMetodoPago
      //  if(metodoPago){
      //   this.items[3].chip = metodoPago
      //  }
      //  console.log('------------------------------------');
      //  console.log(this.$store.getters['abogado/getMetodoPago'];
      //  console.log('------------------------------------');

    },
     posicion(data){
      if(data!=null){
        this.$vuetify.goTo(`#${data}`)
      }
    }
  }
};
</script>